<template>
<div class="toBeCantinue">
  to be continue ...
</div>
</template>
<script>
export default {

}
</script>
<style lang='stylus' scoped>
.toBeCantinue
  position relative
  display block
  width 260px
  height 260px
  border-radius 5px
  // background linear-gradient(to bottom left, white,dimgray)
  // box-shadow 0 2px 30px rgba(0,0,0,0.2)
  font-size 24px
  line-height 260px
  text-align center
  // &:hover
  //   box-shadow 0 2px 30px #FF0033
</style>