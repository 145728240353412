// const test = 'http://localhost:9000/web/iathena',
//   pro = 'https://data.iathena.top/web/iathena',
//   html8 = 'https://data.html8.info/web/iathena',
//   html8Avartor = 'https://html8.info/'

// const baseURL = 'http://localhost:3000'
const baseURL = 'http://data.lzhs.top'
// const baseURL = 'http://42ml122265.wicp.vip'

export  {
    baseURL,
    //   avartorUrl: html8Avartor
}