<template>
  <section class="experience">
    <button @click="web2pdf" ref="btn" class="print-btn">保存简历到PDF</button>
    <ul class="list">
      <span class="line"></span>
      <li class="item end">
        <div class="show">
          <picture class="pic">
            <source
              srcset="../../assets/img/experience/telework.webp"
              type="image/webp"
            />
            <img src="../../assets/img/experience/telework.jpeg" alt="" />
          </picture>
        </div>
        <div class="detail">
          <time>2020 / 7 ~ </time>
          <h2>远程办公（上海某公司）</h2>
          <p>负责PC以及手机端的前台代码（游戏类）。有在线的项目，但是不便于公开，可以私聊获取在线地址。</p>
          <!-- <p>
            <span>在线地址：</span>
            <a href="http://www.dfvan.com">道锋集团官网</a>
          </p> -->
        </div>
      </li>
      <li class="item start">
        <div class="detail">
          <time>2019 / 5 ~ 2020 / 6</time>
          <h2>广州市为人软件科技有限公司</h2>
          <p>上下链 微信公众号开发.前端代码80%都是我写的。</p>
          <span>微信扫一扫，了解更多。</span>
          <div class="qr-box">
            <img src="@/assets/img/sxlQRcode.png" alt="" />
          </div>
        </div>
        <div class="show">
          <picture class="pic">
            <!-- <source
              srcset='../../assets/img/experience/bj.webp'
              type="image/webp"
            > -->
            <img src="../../assets/img/experience/sxl.jpg" alt="" />
          </picture>
        </div>
      </li>
      <li class="item end">
        <div class="show">
          <picture class="pic">
            <source
              srcset="../../assets/img/experience/df.webp"
              type="image/webp"
            />
            <img src="../../assets/img/experience/daofeng_1.jpg" alt="" />
          </picture>
        </div>
        <div class="detail">
          <time>2017 / 3 ~ 2019 / 3</time>
          <h2>广东道锋文化传媒</h2>
          <p>负责公司官网的日常维护及建设</p>
          <p>
            <span>在线地址：</span>
            <a href="http://www.dfvan.com">道锋集团官网</a>
          </p>
        </div>
      </li>
      <li class="item start">
        <div class="detail">
          <time>2014 / 05 ~ 2016 / 12</time>
          <h2>广州标际包装设备有限公司</h2>
          <p>
            公司主要生产检测仪器，用于检测包装袋中的水分，氧气等的含量。我主要负责公司生产的检测设备的安装培训以及售后服务.
          </p>
        </div>
        <div class="show">
          <picture class="pic">
            <source
              srcset="../../assets/img/experience/bj.webp"
              type="image/webp"
            />
            <img src="../../assets/img/experience/biaoji_1.jpg" alt="" />
          </picture>
        </div>
      </li>
      <li class="item end">
        <div class="show">
          <picture class="pic">
            <source
              srcset="../../assets/img/experience/04.webp"
              type="image/webp"
            />
            <img src="../../assets/img/experience/04.jpg" alt="" />
          </picture>
        </div>
        <div class="detail">
          <time>2012 / 10 ~ 2014 / 02</time>
          <h2>柳州市柳南区西江印刷厂</h2>
          <p>
            制版.设计室排好版的PS.pdf文件,制成锌版,并检查图文及网点正常.以及日常中制版机设备的维护.
          </p>
        </div>
      </li>
      <li class="item start">
        <div class="detail">
          <time>2011 / 07 ~ 2012 / 08</time>
          <h2>富士胶片(中国)投资有限公司</h2>
          <p>
            主要负责河南地区客户的CTP版材、软件以及设备的维护。在日常中，拜访客户，了解产品的使用情况。维护客户关系，为销售提供及时的信息
          </p>
        </div>
        <div class="show">
          <picture class="pic">
            <source
              srcset="../../assets/img/experience/fj.webp"
              type="image/webp"
            />
            <img src="../../assets/img/experience/fuji_1.jpg" alt="" />
          </picture>
        </div>
      </li>
      <li class="item end">
        <div class="show">
          <picture class="pic">
            <source
              srcset="../../assets/img/experience/dz.webp"
              type="image/webp"
            />
            <img src="../../assets/img/experience/dazhuang.jpg" alt="" />
          </picture>
        </div>
        <div class="detail">
          <time>2007 / 04 ~ 2011 / 05</time>
          <h2>广州大壮印刷科技有限公司</h2>
          <p>
            主要服务的对象是有日本网屏照排机和CTP的客户.以电话或是上门的方式为客户觖决在生产中遇到的问题。维修设备以及为新安装设备的客户进行软硬件的培训。协助销售同事维护好客户关系。
          </p>
        </div>
      </li>
      <li class="item start">
        <div class="detail">
          <time>2003 / 9 ~ 2006 / 7</time>
          <h2>广西南宁工业职业技术学院</h2>
          <p>
            我的母校，在校的专业是化工机械。在校所学确实影响了我之后很长的一段工作时间。现如今，转做前端，似乎改变有点大，但这也正是乐趣所在。不是吗~~
          </p>
        </div>
        <div class="show">
          <picture class="pic">
            <source
              srcset="../../assets/img/experience/nn.webp"
              type="image/webp"
            />
            <img src="../../assets/img/experience/nanning.jpg" alt="" />
          </picture>
        </div>
      </li>
      <li class="item end">
        <div class="show">
          <picture class="pic">
            <source
              srcset="../../assets/img/experience/time.webp"
              type="image/webp"
            />
            <img src="../../assets/img/experience/shiguang_1.jpg" alt="" />
          </picture>
        </div>
        <div class="detail">
          <time>2000 / 9 ~ 2003 / 7</time>
          <h2>广西柳州融安县高中</h2>
          <p style="opacity: 0.4">不会再回来的时光</p>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
// import Letter from './experience/Letter'
export default {
  data(){
    return {
      // list:[
      //   {
      //     time:'2017 / 3 ~ 2019 / 3',
      //     cn:'广东道锋文化传媒',
      //     work:'负责公司官网的日常维护及建设'
      //   }
      // ]
    }
  },
  components: {
    // Letter
  },
  methods: {
    web2pdf() {
      window.print();
    },
  },
};
</script>


<style lang='stylus' scoped>
.experience {
  position: relative;
  padding: 50px 0;
  background-color: #efefef;
  background-image: linear-gradient(#e7e6e6 1px, transparent 0), linear-gradient(90deg, #e7e6e6 1px, transparent 0);
  background-size: 21px 21px, 21px 21px;
  background-position: center;

  .print-btn {
    top: 10px;
    left: 80%;
  }

  .list {
    position: relative;
    width: 80%;
    margin: 0 auto;

    // border 1px solid red
    .line {
      position: absolute;
      display: block;
      // width 1px
      border-left: 2px solid #336699;
      height: 100%;
      left: 50%;
      top: 0;
    }

    .item {
      display: flex;
      margin-top: 50px;

      &:first-of-type {
        margin-top: 0;
      }

      &.end {
        justify-content: flex-end;

        .show {
          margin-right: 50px;
        }
      }

      &.start {
        justify-content: flex-start;
        text-align: right;

        .detail {
          margin-right: 50px;

          .qr-box {
            width: 100%;
            height: 100px;
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;

            img {
              display: inline-block;
              width: 100px;
              height: 100px;
            }
          }
        }
      }

      .show {
        flex: 0 0 100px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .detail {
        width: 48%;
        font-size: 1.4rem;

        h2 {
          margin: 10px 0;
        }

        p {
          text-indent: 2em;
        }
      }
    }
  }
}
</style>
